import { createStore } from 'vuex'

import cart from './cart'
import user from './user'
import payment from './payment'

export default createStore({
	modules: {
		cart,
		user,
		payment,
	},
})
