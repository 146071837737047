import { createRouter, createWebHistory } from 'vue-router'
import CartLanding from '../views/CartLanding.vue'

const routes = [
	{
		path: '/:cartId',
		props: true,
		name: 'cart',
		component: CartLanding,
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

export default router
