<template>
	<footer>
		<p>© 2023 Garimppa - Todos os direitos reservados</p>
	</footer>
</template>

<style>

footer {
	margin: auto auto 0 auto;
	font-size: 0.7em;
}

</style>
