<template>
	<div class="top-level">
		<message-banner :message="message" />
		<div>
			<img alt="Logo" src="./assets/logo.png" class="logo">
			<router-view @message="showMessage"/>
		</div>
		<aggregate-footer />
	</div>
</template>

<script>
import AggregateFooter from '@/components/AggregateFooter.vue'
import MessageBanner from '@/components/MessageBanner.vue'

export default {
	name: 'App',
	components: {
		AggregateFooter,
		MessageBanner,
	},
	data() {
		return {
			message: null,
			messageTimer: null,
		}
	},
	methods: {
		showMessage(message) {
			if (this.messageTimer) {
				clearTimeout(this.messageTimer)
			}
			this.message = message
			setTimeout(() => {
				this.message = null
			}, 6000)
		},
	},
}

</script>

<style>

/* variables */
:root {
	--garimppa-pink: #EB2E58;
	--garimppa-pink-dark: #D81E4F;
	--garimppa-pink-background: #EB2E5820;
	--garimppa-purple: #5e4785;
	--garimppa-purple-dark: #4a2f6a;
}

body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, 'Poppins', sans-serif;
}

button {
	font-family: -apple-system, 'Poppins', sans-serif;
}

.top-level {
	background-color: #fff;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	max-width: 100vw;
	min-height: 100vh;
}

.top-level > div {
	width: calc(100% - 32pt);
	padding: 0pt 16pt;
}

.logo {
	display: block;
	margin: 16pt auto auto auto;
	max-width: 200px;
}
.bold {
	font-weight: 500;
}
.green {
	color: #4caf50;
}
.green.bold::after {
	content: '';
}

</style>
