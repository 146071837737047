<template>
	<div class="cart-row-trailling green bold">
		<p class="paid-row-title">
			{{ topText }} <font-awesome-icon icon="fa-solid fa-check-circle" />
		</p>
		<p v-if="bottomText" class="cart-row-subtitle paid-row-subtitle">{{ bottomText }}</p>
	</div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

library.add(faCheckCircle)

export default {
	components: {
		FontAwesomeIcon,
	},
	props: {
		topText: {
			type: String,
			required: true,
		},
		bottomText: {
			type: String,
			required: false,
		},
	},
}
</script>

<style>
.cart-row-trailling > .paid-row-title {
	margin: 0;
	font-size: 0.8em;
}

.cart-row-trailling > .paid-row-subtitle {
	font-size: 0.8em;
	margin: 0;
	visibility: hidden;
}

.cart-row:hover .paid-row-subtitle {
	visibility: visible;
}

@media (hover: none) {
	.cart-row-trailling > .paid-row-title {
		font-size: 0.7em;
	}

	.cart-row-trailling > .paid-row-subtitle {
		font-size: 0.7em;
		visibility: visible;
	}
}

</style>
