import axios from 'axios'
import _ from 'lodash'

function markItemsAsPaid(reservations, amountPaid) {
	let total = 0

	// Iterate through the cart items
	reservations.forEach((item) => {
		if (total < amountPaid) {
			total += item.preco_historico
			// eslint-disable-next-line no-param-reassign
			item.isPaid = true
		}
	})
}

export default {
	namespaced: true,
	state: {
		reservations: [],
		loadingItems: false,
		selectedShippingOption: null,
		cart: null,
		error: null,
	},
	getters: {
		store: (state) => (state.cart && state.cart.store),
		event: (state) => (state.cart && state.cart.live),
		shippingOptions: (state, getters) => (_.get(getters, 'event.evento_entrega.itens', [])),
		availableItems: (state, getters) => (_.get(getters, 'event.evento_codigo.itens', [])),
		paidForShippingOption: (state) => (_.get(state, 'cart.paidForShippingOption')),
		paidItems: (state) => (_.filter(state.reservations, (reservation) => (reservation.isPaid))),
		itemsTotal: (state) => {
			const total = _.reduce(state.reservations, (sum, reservation) => {
				if (!reservation.preco_historico) {
					return sum
				}
				if (reservation.fila_espera !== 0 && !reservation.fila_promovido) {
					return sum
				}
				if (reservation.vendido || reservation.pago_por_fora || reservation.excluido) {
					return sum
				}
				return sum + reservation.preco_historico
			}, 0)
			return total
		},
		outstandingBalance: (state, getters) => () => {
			let total = _.get(getters, 'itemsTotal', 0)
			total -= _.get(state, 'cart.totalConfirmedPayments', 0)
			if (_.get(getters, 'paidForShippingOption.valor', 0)) {
				total += _.get(getters, 'paidForShippingOption.valor', 0)
			} else {
				total += _.get(state, 'selectedShippingOption.valor', 0)
			}
			return total
		},
	},
	mutations: {
		setCart(state, cart) {
			state.cart = cart
		},
		setError(state, error) {
			state.error = error
		},
		setLoadingItems(state, loading) {
			state.loadingItems = loading
		},
		setReservations(state, reservations) {
			state.reservations = reservations
		},
		setSelectedShippingOption(state, option) {
			state.selectedShippingOption = option
		},
	},
	actions: {
		async fetchCart({ commit, getters }, cartId) {
			commit('setLoadingItems', true)
			commit('setError', null)
			try {
				const { data } = await axios.get(`${process.env.VUE_APP_API_URL}/live-cart/${cartId}`)
				const amountPaid = _.get(data, 'totalConfirmedPayments', 0) - _.get(data, 'paidForShippingOption.valor', 0)
				if (amountPaid > 0) {
					markItemsAsPaid(data.reservations, amountPaid)
				}
				commit('setCart', _.omit(data, ['reservations']))
				commit('setReservations', data.reservations)
				const { shippingOptions } = getters
				if (shippingOptions.length > 0) {
					const [shippingOption] = shippingOptions
					commit('setSelectedShippingOption', shippingOption)
				}
			} catch (error) {
				commit('setError', error)
			}
			commit('setLoadingItems', false)
		},
		async addItem({ commit, state }, { item, tamanho }) {
			commit('setError', null)
			try {
				const { data } = await axios.post(`${process.env.VUE_APP_API_URL}/live-cart/${state.cart.objectId}/reservation`, {
					item: {
						codigo: item,
						tamanho,
					},
				})
				commit('setCart', _.omit(data, ['reservations']))
				commit('setReservations', data.reservations)
			} catch (error) {
				if (_.get(error, 'response.data.error.message')) {
					commit('setError', _.get(error, 'response.data.error'))
				} else {
					commit('setError', {
						message: 'Erro ao adicionar item ao carrinho',
					})
				}
			}
		},
		async removeItem({ commit, state }, { reservationId }) {
			commit('setError', null)
			try {
				await axios.delete(`${process.env.VUE_APP_API_URL}/live-cart/${state.cart.objectId}/reservation/${reservationId}`)
				const { reservations } = state
				const newReservations = reservations.filter(
					(reservation) => (reservation.objectId !== reservationId),
				)
				commit('setReservations', newReservations)
			} catch (error) {
				commit('setError', error)
			}
		},
	},
}
