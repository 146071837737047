<template>
	<main class="main-cart">
		<div v-if="!hasCart">
			<h1 class="user-data-title">Sacolinha vazia</h1>
			<p>
				Sua sacolinha está vazia.
			</p>
		</div>
		<cart-contents-list
			v-if="hasCart"
			:cart-rows="cartItems"
			:shipping-options="paidForShippingOption ? [paidForShippingOption] : shippingOptions"
			:paid-for-shipping="!!paidForShippingOption"
			:get-total="() => getTotalTextForCart(reservations)"
			:format-currency="formatCurrency"
			:disclaimer="disclaimerText"
			:disabled="disableShippingSelector"
			:ownerHandle="cart && cart.ownerHandle"
			@delete="deleteReservation">
			<!-- <add-to-cart /> -->
		</cart-contents-list>
		<cart-total v-if="hasCart"
			:format-currency="formatCurrency"
			:disclaimer="disclaimerText" />
		<article v-if="hasCart" class="user-data">
			<button v-if="!showSignupForm && !showQRCode"
				class="cart-button" @click="handleContinueClick">
				Finalizar compra
			</button>
			<template v-if="showSignupForm">
				<h1 class="user-data-title">Insira seus dados:</h1>
				<div class="input-wrapper">
					<input type="text"
						id="name-input"
						placeholder="  "
						name="name"
						v-model="userName"
						required>
					<label for="name-input">Nome</label>
					<div class="bar"></div>
				</div>
				<div class="input-wrapper">
					<input type="tel"
							id="phone-input"
							placeholder="  "
							v-mask="['(##) #####-####']"
							v-model="userPhone"
							required>
					<label for="phone-input">Telefone (WhatsApp)</label>
					<div class="bar"></div>
				</div>
			</template>
		</article>
		<div v-if="hasCart && showSignupForm" class="payment-options">
			<button class="cart-button" @click.stop="payCard" :disabled="!isValidPhone">
				<h3>
					Pagar com Cartão
				</h3>
			</button>
			<button class="cart-button" @click.stop="payPix" :disabled="!isValidPhone">
				<h3>
					Pagar com PIX
				</h3>
			</button>
		</div>
		<div class="payment-options" v-if="showQRCode">
			<h2 class="user-data-title">Pagamento com Pix</h2>
			<p>
				Abra o aplicativo do seu banco e selecione
				pagamento com Pix através do copia e cola, copiando o código abaixo:
			</p>
			<button @click.stop="copyQRCode()"
				@keyup="copyQRCode()" class="cart-button" v-if="qrcodeCode">
				Copiar código
			</button>
			<p>
				Você também pode pagar utilizando outro
				dispositivo apontando para o código QRCode a seguir:
			</p>
			<img v-if="qrcodeImage" :src="qrcodeImage" alt="QR Code" />
		</div>
	</main>
</template>

<script>
import { mask } from 'vue-the-mask'
import { mapActions, mapGetters, mapState } from 'vuex'
import _ from 'lodash'
import CartContentsList from '@/components/CartContentsList.vue'
import CartTotal from '@/views/CartTotal.vue'
// import AddToCart from '@/views/AddToCart.vue'

export default {
	name: 'CartLanding',
	directives: {
		mask,
	},
	components: {
		CartContentsList,
		// AddToCart,
		CartTotal,
	},
	props: {
		cartId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			showSignupForm: false,
			showPaymentOptions: false,
			showQRCode: false,
			qrcodeImage: null,
			qrcodeCode: null,
			disableShippingSelector: false,
			itemToAdd: '',
			availableSizesToAdd: [],
			selectedSizeToAdd: null,
		}
	},
	computed: {
		...mapState('cart', ['cart', 'reservations', 'selectedShippingOption', 'error']),
		...mapGetters('cart', ['store', 'event', 'shippingOptions', 'availableItems', 'paidForShippingOption', 'outstandingBalance', 'paidItems']),
		...mapGetters('user', ['isValidPhone']),
		...mapState('payment', ['paymentInfo']),
		userName: {
			get() {
				return this.$store.state.user.name
			},
			set(value) {
				this.$store.commit('user/setName', value)
			},
		},
		userPhone: {
			get() {
				return this.$store.state.user.phone
			},
			set(value) {
				this.$store.commit('user/setPhone', value)
			},
		},
		cartItems() {
			if (!this.cart) {
				return []
			}
			return _.map(this.reservations, (reservation) => ({
				id: reservation.objectId,
				rowTitle: `Código ${reservation.codigo}`,
				rowSubtitle: this.getSubTitleForCartItem(reservation),
				rowEndText: this.formatPriceForCartItem(reservation),
				faded: this.shouldFadeCartItem(reservation),
				paid: reservation.isPaid,
			}))
		},
		totalText() {
			if (!this.cart) {
				return ''
			}
			return this.getTotalTextForCart(this.reservations)
		},
		hasCart() {
			return this.reservations.length > 0
		},
		hasQueuedItems() {
			return _.some(this.reservations, (reservation) => (
				reservation.fila_espera > 0 && !reservation.fila_promovido))
		},
		hasPaidItems() {
			return !_.isEmpty(this.paidItems)
		},
		disclaimerText() {
			const queuedDisclaimer = '⚠ Sua sacolinha possui itens na fila de espera. Eles não serão incluídos neste pagamento. Não se preocupe, você não perderá sua vez.'
			const paidDisclaimer = '✔ Itens já pagos não serão incluídos novamente no pagamento.'
			if (this.hasPaidItems && this.hasQueuedItems) {
				return `${queuedDisclaimer}\n${paidDisclaimer}`
			}
			if (this.hasQueuedItems) {
				return queuedDisclaimer
			}
			if (this.hasPaidItems) {
				return paidDisclaimer
			}
			return null
		},
	},
	watch: {
		itemToAdd() {
			_.debounce(() => {
				const tempItem = this.itemToAdd.trim()
				if (tempItem.length > 0) {
					const foundCodes = _.filter(this.availableItems, (i) => i.codigo === tempItem)
					if (foundCodes.length > 0) {
						this.availableSizesToAdd = _(foundCodes).map('tamanho')
							.uniq()
							.omit(_.isNil)
							.omit('')
							.value()
						const [selectedSizeToAdd] = this.availableSizesToAdd
						this.selectedSizeToAdd = selectedSizeToAdd
					}
				}
			}, 500)
		},
		cartId() {
			if (!this.cart || this.cart.objectId !== this.cartId) {
				this.$store.dispatch('cart/fetchCart', this.cartId)
			}
		},
		paymentMethod() {
			if (this.paymentMethod) {
				this.cartItemsClass = 'cart-items cart-items--payment-selected'
			} else {
				this.cartItemsClass = 'cart-items'
			}
		},
	},
	created() {
		this.$store.dispatch('cart/fetchCart', this.cartId)
	},
	methods: {
		...mapActions('payment', ['createPayment']),
		handleContinueClick() {
			this.showSignupForm = true
		},
		payCard() {
			this.pay('link')
		},
		payPix() {
			this.pay('pix')
		},
		pay(paymentType) {
			this.disableShippingSelector = true
			this.createPayment({
				cartId: this.cartId,
				paymentType,
				totalValue: this.outstandingBalance(),
				user: {
					name: this.userName,
					phone: this.userPhone,
				},
				shippingOption: this.paidForShippingOption || this.selectedShippingOption,
			}).then(() => {
				if (this.paymentInfo && this.paymentInfo.paymentURL) {
					window.open(this.paymentInfo.paymentURL, '_blank')
				} else if (this.paymentInfo && this.paymentInfo.qrCode) {
					this.qrcodeImage = this.paymentInfo.qrCode.qrcode_imagem
					this.qrcodeCode = this.paymentInfo.qrCode.qrcode_codigo
					this.showSignupForm = false
					this.showQRCode = true
				}
			})
		},
		getSubTitleForCartItem(cartItem) {
			if (cartItem.fila_espera > 0 && !cartItem.fila_promovido) {
				return 'Na fila'
			}
			if (cartItem.pago_por_fora) {
				return 'Pago por fora'
			}
			if (cartItem.vendido) {
				return 'Vendido'
			}
			return cartItem.tamanho ? `Tam. ${cartItem.tamanho}` : ''
		},
		formatPriceForCartItem(cartItem) {
			if (cartItem.preco_historico) {
				return this.formatCurrency(cartItem.preco_historico)
			}
			return null
		},
		formatCurrency(value) {
			return value.toLocaleString('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			})
		},
		shouldFadeCartItem(cartItem) {
			return (cartItem.fila_espera > 0 && !cartItem.fila_promovido)
				|| cartItem.pago_por_fora || cartItem.vendido
		},
		copyQRCode() {
			navigator.clipboard.writeText(this.qrcodeCode)
		},
		shortenText(text) {
			const maxLength = 80
			if (text.length > maxLength) {
				const charsToRemove = text.length - maxLength
				const startIndex = Math.floor(text.length / 2 - charsToRemove / 2)
				const endIndex = startIndex + charsToRemove
				const shortenedText = `${text.substring(0, startIndex)}...${text.substring(endIndex)}`
				return shortenedText
			}
			return text
		},
		deleteReservation(reservationId) {
			this.$store.dispatch('cart/removeItem', {
				reservationId,
			}).then(() => {
				if (this.error) {
					if (this.error.message) {
						this.showMessage(this.error.message, 'warn')
					}
					return
				}
				this.showMessage('Item removido da sacolinha.', 'info')
			})
		},
	},
}
</script>

<style>
.main-cart {
	background-color: #fff;
	display: block;
	flex-flow: column nowrap;
	max-width: 600px;
	margin: auto;
}

.cart-button {
	margin-top: 1em;
	color: #fff;
	background-color: var(--garimppa-pink);
	border: 1px transparent solid;
	border-radius: 0.5em;
	padding: 0.5em 0;
	font-size: 1.2em;
	width: 50%;
	min-width: 200pt;
	transition: all 0.25s;
}

.cart-button:hover {
	background-color: #fff;
	color: var(--garimppa-pink);
	border: 1px solid var(--garimppa-pink);
}

.cart-button:active {
	color: #fff;
	background-color: var(--garimppa-pink-dark);
}

.cart-button:disabled, .cart-button:disabled:hover {
	color: #fff;
	background-color: #ddd;
	border: 1px solid #ddd;
}

.payment-options {
	margin-top: 1em;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
}

.cart-button:has(input:checked) {
	color: #fff;
	background-color: var(--garimppa-pink-dark);
}

.cart-button h3 {
	margin: 0;
	font-weight: 400;
	font-size: 1em;
}

.user-data {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
}

.user-data-title {
	width: 100%;
}

label {
	display: block;
	font-weight: 400;
	margin-bottom: 5px;
}

.input-wrapper {
	width: 100%;
	position: relative;
	margin-bottom: 25px;
	font-family: Poppins, sans-serif;
	font-size: 1em;
}

.input-wrapper label {
	position: absolute;
	top: 10px;
	font-size: 1em;
	left: 0;
	transition: all 0.33s ease;
	font-family: Poppins, sans-serif;
}

input[type="text"], input[type="tel"], select {
	font-family: Poppins, sans-serif;
	display: block;
	width: 100%;
	padding: 10px 0;
	border: none;
	font-size: 1em;
	border-bottom: 1px solid #ddd;
	background: transparent;
	transition: all 0.3s ease;
}

input[type="text"]:focus, input[type="tel"]:focus {
	outline: none;
	border-bottom-color: var(--garimppa-pink);
}

input[type="text"]:focus ~ label,
input[type="tel"]:focus ~ label,
input:not(:placeholder-shown) ~ label,
select:not(:checked) ~ label {
	top: -12px;
	font-size: 12px;
	color: var(--garimppa-pink);
}

.bar {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 1px;
	background-color: #ddd;
	transition: all 0.3s ease;
}

input[type="text"]:focus ~ .bar,  input[type="tel"]:focus ~ .bar {
	background-color: var(--garimppa-pink);
}

.qr-container {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	text-align: center;
}

.qr-code-code {
	color: var(--garimppa-pink);
	cursor: pointer;
}
.qr-code-code:hover {
	color: var(--garimppa-pink-dark);
}

</style>
