<template>
	<div class="banner-container">
		<Transition name="slide">
			<div v-if="message" :class="`banner ${message.type || 'info'}`">
				<div class="banner-text">
					{{ message.text }}
				</div>
			</div>
		</Transition>
	</div>
</template>

<script>

export default {
	name: 'MessageBanner',
	props: {
		message: {
			type: Object,
			required: false,
		},
	},
}

</script>

<style>
.banner-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	overflow: hidden;
	z-index: 1000;
}

.banner {
	background-color: var(--garimppa-purple);
	color: #fff;
	padding: 8pt 16pt;
	text-align: center;
}

.banner-text {
	font-size: 14pt;
}

.banner.info {
	background-color: var(--garimppa-purple);
}

.banner.success {
	background-color: #4caf50;
}

.banner.warn {
	background-color: #ff9800;
}

.slide-enter-active {
  animation: bounce-in 0.3s ease-out;
}
.slide-leave-active {
  animation: bounce-in 0.5s ease-out reverse;
}
@keyframes bounce-in {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}

</style>
