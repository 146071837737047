<template>
	<div :class="cartRowClass">
		<div class="cart-row-leading">
			<slot name="title" >
				<span v-if="rowTitle" :class="titleClass">{{ rowTitle }}</span>
			</slot>
			<slot name="subtitle">
				<span v-if="rowSubtitle" class="cart-row-subtitle">{{ rowSubtitle }}</span>
			</slot>
		</div>
		<slot name="end">
			<cart-trailling-paid v-if="paid"
				:topText="paidTopText || 'Pago'"
				:bottomText="rowEndText" />
			<div v-else-if="rowEndText" class="cart-row-trailling-with-delete">
				<span :class="traillingClass">{{ rowEndText }}</span>
				<font-awesome-icon v-if="deleteable" @click="deleteCartItem(id)"
					class="delete-button" :icon="['fas', 'xmark']" />
			</div>
		</slot>
	</div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import CartTraillingPaid from './CartTraillingPaid.vue'

library.add(faXmark)

export default {
	name: 'CartItemRow',
	components: {
		FontAwesomeIcon,
		CartTraillingPaid,
	},
	props: {
		cartItemModel: {
			type: Object,
			required: false,
		},
	},
	methods: {
		deleteCartItem(id) {
			this.$emit('delete', id)
		},
	},
	data() {
		const {
			id,
			rowTitle,
			rowSubtitle,
			rowEndText,
			rowEndClass,
			paid,
			paidTopText,
			compact,
			faded,
			bold,
			deleteable,
		} = (this.cartItemModel || {})
		return {
			id,
			rowTitle,
			rowSubtitle,
			rowEndText,
			paidTopText,
			deleteable,
			compact,
			paid,
			titleClass: `cart-row-title${faded ? ' faded' : ''}${compact ? ' compact' : ''}${bold ? ' bold' : ''}`,
			traillingClass: `cart-row-trailling${faded ? ' faded' : ''}${compact ? ' compact' : ''}${bold ? ' bold' : ''}${rowEndClass ? ` ${rowEndClass}` : ''}`,
			cartRowClass: `cart-row${compact ? ' compact' : ''}${faded ? ' faded' : ''}${bold ? ' bold' : ''}`,
		}
	},
}

</script>

<style>

.cart-row {
	user-select: none;
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	width: 100%;
	padding: 1rem 0;
	border-bottom: 1px solid #eaeaea;
}

.cart-row-leading {
	display: flex;
	flex-flow: column nowrap;
}

.cart-row-title {
	font-size: 1.2em;
	font-weight: 400;
	margin: 0;
}

.faded {
	color: #999;
}
.cart-row-trailling.faded {
	display: inline-block;
	position: relative;

}

.cart-row-trailling.faded::before {
  content: "";
  position: absolute;
  top: calc(50% - 0.5pt);
  left: 0;
  width: 100%;
  height: 1pt;
  opacity: 1;
  background-color: #999;
  transform: rotate(4deg);
}

.cart-row-subtitle {
	font-size: 0.9rem;
	font-weight: 400;
	margin: 0;
	padding-left: 0.5em;
}

.cart-row.no-border {
	border-bottom: none;
}

.delete-button {
	width: 12pt;
	height: 12pt;
	padding: 4pt;
	border-radius: 50%;
	border: none;
	background-color: #999;
	color: #FFF;
	visibility: hidden;
	margin-left: 0.5em;
	cursor: pointer;
}
.cart-row-trailling-with-delete {
	display: flex;
	align-items: center;
}

.cart-row:hover .delete-button {
	visibility: visible;
}

.cart-row-title.compact {
	font-size: 1em;
	font-weight: 400;
}

.cart-row.compact {
	padding: 0.5rem 0;
}

.cart-row-trailling.compact {
	font-size: 0.9em;
}

.cart-row-trailling.bold {
	font-weight: 600;
	font-size: 1.2em;
}
.cart-row-title.bold {
	font-weight: 600;
}
.cart-row.bold {
	border-bottom: 1px solid black;
}
.cart-row-trailling .cart-row-subtitle {
	padding: 0;
	text-align: right;
}
</style>
