import axios from 'axios'

export default {
	namespaced: true,
	state: {
		paymentInfo: null,
		paymentError: null,
	},
	mutations: {
		setPaymentInfo(state, paymentInfo) {
			state.paymentInfo = paymentInfo
		},
		setPaymentError(state, paymentError) {
			state.paymentError = paymentError
		},
	},
	actions: {
		async createPayment({ commit }, {
			paymentType,
			totalValue,
			shippingOption,
			cartId,
			user,
		}) {
			commit('setPaymentInfo', null)
			commit('setPaymentError', null)
			try {
				const { data } = await axios.post(`${process.env.VUE_APP_API_URL}/live-cart/${cartId}/pay/${paymentType}`, {
					totalValue,
					shippingOption,
					userInfo: user,
				})
				commit('setPaymentInfo', data)
			} catch (error) {
				commit('setPaymentError', error)
			}
		},
	},
}
