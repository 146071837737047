<template>
	<article class="cart-items">
		<h1>Olá @{{ ownerHandle || '' }}, aqui está sua sacolinha:</h1>
		<cart-item-row
			v-for="(cartItem, index) in cartRows"
			:key="cartItem.id"
			:cart-item-model="cartItem"
			:class="index === (cartRows.length - 1) ? 'no-border' : '' "
			@delete="deleteCartItem" />
		<slot>
		</slot>
		<cart-item-row v-if="shippingOptions.length === 1"
			:cart-item-model="{
				rowTitle: 'Entrega:',
				rowSubtitle: shippingOptions[0].texto,
			}">
			<template v-if="paidForShipping" v-slot:end>
				<cart-trailling-paid v-if="shippingOptions[0]"
					topText="Paga"
					:bottomText="formatCurrency(shippingOptions[0].valor)"
					/>
			</template>
		</cart-item-row>
		<template v-else-if="cartRows.length > 0">
			<cart-item-row>
				<template v-slot:title>
					<label for="shipping-option" id="shipping-option-label" class="cart-row-title">
						Entrega:
					</label>
				</template>
				<template v-slot:subtitle>
					<select id="shipping-option" class="cart-row-subtitle" v-model="shippingOption"
						aria-labelledby="shipping-option-label" :disabled="disabled">
						<option v-for="shippingOption in shippingOptions"
							:key="shippingOption.texto"
							:value="shippingOption">
							{{ shippingOption.texto }} - {{ formatCurrency(shippingOption.valor)}}
						</option>
					</select>
				</template>
				<template v-slot:end>
					<p v-if="shippingOption" class="cart-row-end-text">
						{{ formatCurrency(shippingOption.valor) }}
					</p>
				</template>
			</cart-item-row>
		</template>
	</article>
</template>

<script>
import CartItemRow from '@/components/CartItemRow.vue'
import CartTraillingPaid from '@/components/CartTraillingPaid.vue'

export default {
	name: 'CartContentsList',
	components: {
		CartTraillingPaid,
		CartItemRow,
	},
	computed: {
		totalText() {
			return this.getTotal()
		},
		shippingOption: {
			get() {
				return this.$store.state.cart.selectedShippingOption
			},
			set(value) {
				this.$store.commit('cart/setSelectedShippingOption', value)
			},
		},
	},
	methods: {
		deleteCartItem(id) {
			this.$emit('delete', id)
		},
	},
	props: {
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		cartRows: {
			type: Array,
			required: true,
		},
		shippingOptions: {
			type: Array,
			required: true,
		},
		getTotal: {
			type: Function,
			required: true,
		},
		formatCurrency: {
			type: Function,
			required: true,
		},
		ownerHandle: {
			type: String,
			required: false,
		},
		paidForShipping: {
			type: Boolean,
			required: false,
		},
	},
}

</script>

<style>

.cart-items {
	display: flex;
	flex-flow: column nowrap;
}
.cart-row-subtitle.disclaimer {
	padding: 0;
	white-space: pre-wrap;
}

</style>
