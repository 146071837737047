<template>
	<div>
		<cart-item-row v-for="item in content"
			:key="item.rowTitle+item.rowEndText"
			:cart-item-model="item">
			<template v-slot:subtitle>
				<p v-if="disclaimer" class="cart-row-subtitle disclaimer">
					{{ disclaimer }}
				</p>
			</template>
		</cart-item-row>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CartItemRow from '@/components/CartItemRow.vue'

export default {
	components: {
		CartItemRow,
	},
	props: {
		disclaimer: {
			type: String,
			required: false,
		},
		formatCurrency: {
			type: Function,
			required: true,
		},
	},
	data() {
		return {
			content: [],
		}
	},
	mounted() {
		this.content = this.items()
	},
	watch: {
		selectedShippingOption() {
			this.content = this.items()
		},
	},
	methods: {
		items() {
			if (!this.cart) {
				return []
			}
			if (this.cart.totalConfirmedPayments > 0) {
				let paidForShippingValue = 0
				if (this.paidForShippingOption) {
					paidForShippingValue = this.paidForShippingOption.valor
				}
				const items = [
					{
						rowTitle: `Produtos pagos${paidForShippingValue ? ' (incl. frete)' : ''}:`,
						rowEndText: null,
						compact: true,
						paid: true,
						paidTopText: this.formatCurrency(
							this.cart.totalConfirmedPayments,
						),
					},
				]
				if (this.outstandingBalance() > 0) {
					items.push(
						{
							rowTitle: 'Saldo a pagar:',
							rowEndText: this.formatCurrency(this.outstandingBalance()),
							bold: true,
						},
					)
				}
				return items
			}
			return [{
				rowTitle: 'Total:',
				rowEndText: this.formatCurrency(
					this.outstandingBalance(),
				),
				bold: true,
			}]
		},
	},
	computed: {
		...mapState('cart', ['selectedShippingOption', 'cart', 'reservations']),
		...mapGetters('cart', ['paidForShippingOption', 'itemsTotal', 'outstandingBalance']),
	},
}

</script>

<style>

</style>
