export default {
	namespaced: true,
	state: {
		name: '',
		phone: '',
	},
	getters: {
		isValidPhone: (state) => (state.phone.length === 11),
	},
	mutations: {
		setName(state, name) {
			state.name = name
		},
		setPhone(state, phone) {
			state.phone = phone.replace(/\D/g, '')
		},
	},
}
